import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Script from 'next/script';

import websiteConfig from '../../../configs/websiteConfig';

function HeadScriptsHtml() {
  const timeoutRef = useRef(0);
  const [loadScripts, setLoadScripts] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const loadScript = useCallback((src, id, onLoad) => {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (onLoad) onLoad();
    };
  }, []);

  const loadTawk = useCallback((onLoad) => {
    if (!websiteConfig.tawkId) {
      return;
    }
    const Tawk_API = window.Tawk_API || {};
    const Tawk_LoadStart = new Date();
    // eslint-disable-next-line func-names
    (function () {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = `https://embed.tawk.to/${websiteConfig.tawkId}/${websiteConfig.tawkType}`;
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
      s1.onload = () => {
        setTimeout(() => {
          if (onLoad) onLoad();
        }, 1000);
      };
    })();
  }, []);

  const onSubscriptionChange = useCallback((isSubscribed) => {
    if (window.OneSignal) {
      if (isSubscribed) {
        window.OneSignal.showNativePrompt();
        if (user) {
          window.OneSignal.setExternalUserId(user.id);
        }
      } else {
        window.OneSignal.removeExternalUserId();
      }
    }
  }, []);

  const onOneSignalLoad = useCallback(() => {
    if (!websiteConfig.oneSignal) {
      return;
    }
    const OneSignal = window.OneSignal || [];

    if (window.OneSignal && !window.OneSignal.initialized) {
      OneSignal.push(() => {
        OneSignal.init({
          appId: websiteConfig.oneSignal,
          notifyButton: {
            enable: true,
          },
        });

        OneSignal.on('subscriptionChange', onSubscriptionChange);
      });
    }
  }, []);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setLoadScripts(true);
    }, 5000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (loadScripts) {
      if (websiteConfig.oneSignal) {
        loadScript(
          'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
          'one-signal',
          onOneSignalLoad
        );
      }

      if (websiteConfig.tawkId) {
        loadTawk(() => {
          if (
            window?.Tawk_API &&
            typeof window.Tawk_API.hideWidget === 'function'
          ) {
            window.Tawk_API.hideWidget();
          }
          // dispatch(showHideChat(true));
        });
      }
    }
  }, [loadScripts]);

  return (
    <>
      {loadScripts && (
        <>
          {websiteConfig.googleTagId && (
            <Script
              strategy="lazyOnload"
              src={`https://www.googletagmanager.com/gtag/js?id=${websiteConfig.googleTagId}`}
            />
          )}
          {websiteConfig.googleTagId && (
            <Script
              strategy="lazyOnload"
              src="https://www.googletagmanager.com/gtag/js?id=AW-11139759699"
            />
          )}
          {websiteConfig.googleTagManagerDataLayer && (
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${websiteConfig.googleTagManagerDataLayer}');`,
              }}
            />
          )}
          {websiteConfig.googleTagId && (
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${websiteConfig.googleTagId}');
  gtag('config', 'AW-11139759699');`,
              }}
            />
          )}
          {websiteConfig.googleAnalyticsId && (
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', '${websiteConfig.googleAnalyticsId}', 'auto');`,
              }}
            />
          )}

          {websiteConfig.facebookTrackingId && (
            <>
              <Script
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                  __html: `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${websiteConfig.facebookTrackingId}');
            fbq('track', 'PageView');`,
                }}
              />
              <noscript>
                <img
                  height="1"
                  width="1"
                  style={{ display: 'none' }}
                  alt="facebook"
                  src={`https://www.facebook.com/tr?id=${websiteConfig.facebookTrackingId}&ev=PageView&noscript=1`}
                />
              </noscript>
            </>
          )}
          {websiteConfig.hotjarId && websiteConfig.hotjarSV && (
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${websiteConfig.hotjarId},hjsv:${websiteConfig.hotjarSV}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export default React.memo(HeadScriptsHtml);
